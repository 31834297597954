import { COLORS } from "@/styles/colors";

import { Stack, Text, Title } from "@mantine/core";
import Image from "next/legacy/image";
import Link from "next/link";

import WhiteButton from "@/components/atoms/WhiteButton";
import { getPlainLayout } from "@/components/layouts";
import { NextPageWithLayout } from "@/types";

const Custom404: NextPageWithLayout = () => {
  return (
    <Stack pt={150} align="center">
      <Title order={1} sx={{ color: COLORS.rakushifuBlue, fontSize: 64 }}>
        404
      </Title>
      <Image
        src="/images/penguin.png"
        height={164}
        width={101}
        objectFit="contain"
        alt="らくしふ労務管理"
      />
      <Text size="lg" weight="bold" sx={{ color: COLORS.rakushifuBlue }} mb={24}>
        このページは存在しません
      </Text>
      <Link href="/employees" passHref legacyBehavior>
        <WhiteButton sx={{ width: 200 }}>らくしふ労務管理に戻る</WhiteButton>
      </Link>
    </Stack>
  );
};

Custom404.getLayout = getPlainLayout;

export default Custom404;
